
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  setup() {
    const showModal = ref(true)
    const videoId = ref('iu16vofmho')
    const loadScript = (src: string) => {
      const newScript = document.createElement('script')
      newScript.setAttribute('src', src)
      newScript.async = true
      document.body.appendChild(newScript)
    }

    onMounted(() => {
      loadScript(`https://fast.wistia.com/embed/medias/${videoId.value}.jsonp`)
      loadScript('https://fast.wistia.com/assets/external/E-v1.js')
    })

    return {
      showModal,
      videoId,
    }
  },
})
